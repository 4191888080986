// primary btn
@mixin button-primary() {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--buttonPaddingMobile);
  font-size: var(--buttonFontSizeMobile);
  background: var(--buttonBackground);
  border-radius: var(--borderRadius);
  border: var(--buttonBorder);
  text-align: center;
  color: var(--buttonColor);
  text-transform: var(--buttonTextTransform);
  transition: var(--buttonTransition);
  margin: var(--buttonMargin);
  cursor: pointer;
  font-weight: var(--buttonFontWeight);
  width: max-content;
  @media all and (min-width: $sm) {
    font-size: var(--buttonFontSize);
    padding: var(--buttonPadding);
  }
  &:hover,
  &:hover:enabled,
  &:focus,
  &:active {
    @include button-primary-hover();
    outline: none;
  }
}
// primary btn on hover or on focus
@mixin button-primary-hover() {
  border: var(--buttonBorderHover);
  background: var(--buttonBackgroundHover);
  color: var(--buttonColorHover);
}

// primary btn
@mixin button-secondary() {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--buttonPaddingMobile);
  font-size: var(--buttonFontSizeMobile);
  background: var(--backgroundColor);
  border-radius: var(--borderRadius);
  border: var(--buttonBorder);
  text-align: center;
  color: var(--brandColor);
  text-transform: var(--buttonTextTransform);
  text-decoration: none;
  transition: var(--buttonTransition);
  margin: var(--buttonMargin);
  font-weight: var(--buttonFontWeight);
  cursor: pointer;
  width: max-content;
  @media all and (min-width: $sm) {
    font-size: var(--buttonFontSize);
    padding: var(--buttonPadding);
  }
  &:hover,
  &:hover:enabled,
  &:focus,
  &:active {
    @include button-secondary-hover();
    outline: none;
  }
}
// primary btn on hover or on focus
@mixin button-secondary-hover() {
  border-color: var(--backgroundColor);
  background: var(--brandColor);
  color: var(--backgroundColor);
}
