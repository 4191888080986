@import 'media-query.mixins';
@import 'buttons.mixins';

// Makes the scrollPositionRestoration without the smooth scroll
:root {
  scroll-behavior: auto;
}

html {
  scroll-behavior: smooth;
}

/* You can add global styles to this file, and also import other style files */
/* ========== GLOBALS =========== */
body {
  margin: var(--headerHeightMobile) 0 0 0;
  overflow-x: hidden;

  line-height: 1.2;
  //@include scrollbars();
  // @include for-tablet-landscape-up {
  //   margin-top: var(--headerHeight);
  // }

  background: var(--bodyBackground);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: contain;

  @include for-tablet-landscape-up {
    background-size: cover;
  }

  * {
    outline: none;
  }

  /* ========== COMMONS =========== */
  .page-content {
    width: var(--contentWidth);
    max-width: $xxl;
    margin: 0 auto;
  }

  /* ========== TEXT =========== */

  .text-danger {
    color: red;
  }

  .tab-msg {
    text-align: center;
    color: var(--textColor);
  }

  a {
    text-decoration: none;
  }

  a {

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  p {
    -webkit-text-size-adjust: none;
  }

  .disabled,
  .swiper-button-disabled {
    opacity: var(--swiper-item-disabled-opacity, 0.4);
    pointer-events: none;
  }

  .title {
    font-size: var(--titleSizeMobile);
    color: var(--titleColor);
    text-transform: var(--titleTextTransform);
    padding: var(--titlePadding);
    font-weight: var(--titleFontWeight);

    @include for-tablet-landscape-up {
      font-size: var(--titleSize);
    }
  }

  .subtitle {
    font-size: var(--subtitleSizeMobile);
    color: var(--subtitleColor);
    text-transform: var(--subtitleTextTransform);
    padding: var(--subtitlePadding);
    font-weight: var(--subtitleFontWeight);
    line-height: var(--subtitleLineHeight);

    @include for-tablet-landscape-up() {
      font-size: var(--subtitleSize);
    }
  }

  ul.info-list {
    list-style: var(--infoListStyle);
    margin: 0;
    padding: var(--infoListPadding);

    li {
      display: flex;
      flex-direction: var(--infoListItemMarginFlexDirection);
      margin: var(--infoListItemMargin);

      .subtitle {
        display: flex;
        min-width: var(--inforListSubtitleMinWidth);
      }
    }

    .semicolon {
      display: var(--infoListSemiColonDisplay);
    }

    .comma {
      display: var(--infoListCommanDisplay);
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .button,
  .p-button {
    @include button-primary;
    box-shadow: none !important;
  }

  .button-secondary {
    @include button-secondary;
  }

  .slider-button {}

  .cta-button {}

  .view-all-button {}

  .watch-trailer-button {}

  .see-more-btn {}

  /* ========== COMPONENTS =========== */

  .channel-slide {
    .video-thumbnail {

      .ctrl-play.center,
      .load-more-movie-detail {
        display: none !important;
      }
    }
  }

  ush-video-fullscreen {
    .video-container {
      background-color: black !important;

      @media all and (orientation: portrait) {
        display: flex;
        align-items: center;

        video {
          height: auto;
        }
      }
    }
  }

  /* ========== COMPONENTS - LAYOUT =========== */

  .page-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--headerHeightMobile));

    .page-content-wrapper {
      flex: 1 0 auto;
    }

    @include for-tablet-landscape-up {
      min-height: calc(100vh - var(--headerHeight));
    }
  }

  /* ========== FOOTER =========== */
  ush-footer {
    flex-shrink: 0;
  }

  #footer {
    a {
      text-decoration: none;
    }
  }

  /* ========== HEADER =========== */
  /* ========== COMPONENTS - PAGE =========== */
  // synopsis
  ush-movie-synopsis {
    p {
      margin: 0;
      padding: 0;
    }
  }

  /* ========== ABOUT PAGE =========== */
  /* ========== AUTH PAGE =========== */
  /* ========== BRANDED PAGE =========== */
  .branded-page {
    .menu {
      a {
        max-width: 300px;
      }
    }
  }

  /* ========== JOIN COMMUNITY PAGE =========== */
  /* ========== MOVIES PAGE =========== */
  ush-movies-page {
    #input-movie-cast {
      width: 100%;
    }
  }

  /* ========== MOVIE DETAIL PAGE =========== */
  ush-video-thumbnail.main-video-thumbnail {
    .video-thumbnail {
      img {
        max-height: calc(100vh - var(--headerHeight));
        object-fit: cover;
        width: 100vw;
        height: 100%;
      }
    }
  }

  ush-movie-detail-page {
    .header-msg {
      padding: var(--movieDetailTitlesPadding);
      color: var(--movieDetailTitlesColor);
    }
  }

  /* ========== CAROUSEL ================ */
  .carousel__wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
    grid-auto-rows: min-content;

    @media all and (min-width: $md) {
      display: grid;
    }

    .carousel__title {
      grid-column: 1;
      grid-row: 1;
      row-gap: 5px;
      column-gap: 20px;
      display: flex;
      width: max-content;
      max-width: 100%;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .carousel__view-all {
      grid-column: 2;
      grid-row: 1;
      align-self: center;
    }

    .carousel__content {
      grid-column: 1 / 6;
      grid-row: 2 / 3;
      position: relative;
    }

    .carousel__left-arrow {
      grid-column: 4;
      grid-row: 1;
    }

    .carousel__right-arrow {
      grid-column: 5;
      grid-row: 1;
    }

    h2 {
      margin: 0;
    }

    .swiper-button-disabled {
      opacity: 0.2;
    }

    .carousel__content {
      display: block;
    }

    :host ::ng-deep {
      ush-arrow {
        i {
          font-size: 30px;
        }
      }
    }

    .carousel__left-arrow,
    .carousel__right-arrow {
      display: none;

      @media all and (min-width: $md) {
        display: block;
      }
    }

    .error-msg {
      text-align: center;
      padding: 30px;
      color: black;
    }

    swiper-container::part(scrollbar) {
      position: relative;
      margin-top: 10px;

      @media all and (min-width: $md) {
        display: none;
      }
    }
  }

  .slider-container {
    .video-thumbnail-title {
      font-size: var(--videoThumbnailPageContentSliderFontSize) !important;
    }

    .controls {
      top: unset !important;
      transform: translate(-50%, 0) !important;
    }
  }

  #channel-carousel {
    position: relative;

    .slide-wrap {
      cursor: auto !important;
    }
  }

  /* ========== BRANDED PAGE =========== */
  .branded-page {
    .uplay-container {
      align-items: center !important;

      .items-container {
        margin-bottom: 20px;
      }
    }
  }

  /* ========== SPECIAL PAGE =========== */

  // Arrow
  .arrow-long {
    position: relative;
    height: 2px;
    width: 70px;
    background: var(--arrowColor);
  }

  .left {
    transform: rotate(180deg);
  }

  .arrow-long:after {
    content: '';
    border-right: 2px solid var(--arrowColor);
    height: 25px;
    transform: rotate(45deg);
    position: absolute;
    right: 8px;
    margin-top: -3px;
    display: block;
  }

  .arrow-long:before {
    content: '';
    border-right: 2px solid var(--arrowColor);
    height: 25px;
    transform: rotate(315deg);
    position: absolute;
    bottom: 100%;
    right: 8px;
    margin-bottom: -5px;
    display: block;
  }

  .arrow-channel-slide-left,
  .arrow-channel-slide-right {
    transition: transform 0.3s ease;
    cursor: pointer;
    display: flex;
    height: 30px;
    width: 90px;
    align-items: center;
    justify-content: center;
  }

  .arrow-channel-slide-left {

    &:hover,
    &:focus {
      transform: scale(1.2) rotate(180deg);
    }
  }

  .arrow-channel-slide-right {

    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }

  .arrows-top-side {
    display: flex;
  }

  .caruosel-ctrls {
    display: flex;

    >* {
      width: var(--carouselControlsArrowWidth);
      height: var(--carouselControlsArrowHeight);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      color: #b4b4b4;
      cursor: pointer;
      transition: var(--transitionsTime);
      border: var(--carouselControlsArrowBorder);
    }
  }

  .transparent-background {
    background-color: transparent !important;
  }

  .p-dropwdown.p-focus {
    box-shadow: none;
    border: none;
  }

  .no-pointer {
    pointer-events: none;
  }

  // FIX GOOGLE PLACES AUTOCOMPLETE

  .pac-container {
    z-index: 999999999 !important;
  }
}

// NEWS GLOBAL

.ql-size-huge {
  font-size: var(--ql-size-huge, 2rem);
}

.ql-size-large {
  font-size: var(--ql-size-large, 1.5rem);
}

.ql-size-small {
  font-size: var(--ql-size-large, 0.6rem);
}

.news-page {
  --ql-indent: 4rem;
}

.ql-indent-1 {
  text-indent: calc(var(--ql-indent) * 1);
}

.ql-indent-2 {
  text-indent: calc(var(--ql-indent) * 2);
}

.ql-indent-3 {
  text-indent: calc(var(--ql-indent) * 3);
}

.ql-indent-4 {
  text-indent: calc(var(--ql-indent) * 4);
}

.ql-indent-5 {
  text-indent: calc(var(--ql-indent) * 5);
}

.ql-indent-2 {
  text-indent: 8rem;
}

.ql-align-center {
  text-align: center;
}

.ql-align-end {
  text-align: end;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

blockquote {
  position: relative;
  text-indent: 4rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    background-color: black;
    width: 3px;
    height: 100%;
  }
}

.ql-video {
  width: var(--ql-video-width, 100%);
  width: var(--ql-video-height, 100%);
  aspect-ratio: var(--ql-video-aspect-ratio, 16 / 9);
}

ush-showtime-streaming-in-cinemas {
  .p-accordion .p-accordion-content {
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  }
}

// FIX VIMEO CAROUSEL CHANNEL

#channel-carousel ush-vimeo-player iframe {
  position: static;
}

.p-panel .p-panel-content {
  background-color: transparent;
}

// FORM FIELDS
.form-field {
  label:first-letter {
    text-transform: capitalize;
  }
}

.form-field__mandatory {
  &::after {
    content: '*';
    color: red;
    margin: 0 2px;
  }
}