@import 'styles.variables';
@import './app.styles.variables.scss';
@import 'media-query.mixins';
@import 'fonts.mixins';

@import '../app/movies/love/love.component';

@include font-family-bundle(
  'mainfont',
  'https://cdnstatic.usheru.com/fonts/CabinCondensed-Regular.ttf',
  'https://cdnstatic.usheru.com/fonts/zenantique-regular_nor.woff2'
);

@include font-family-bundle(
  'news',
  'https://cdnstatic.usheru.com/fonts/CabinCondensed-Regular.ttf',
  'https://cdnstatic.usheru.com/fonts/cabin-semibold_nor.woff2'
);

@include font-family-bundle(
  'love-47',
  'https://cdnstatic.usheru.com/fonts/Century_Gothic.otf',
  'https://cdnstatic.usheru.com/fonts/Century_Gothic_Bold.otf'
);

:root {
  --colorLightGrey: #b3b3b3;
  // channel carousel heights
  --carousel-channel-height-mobile: 380px;
  --carousel-channel-height-tablet: 500px;
  --carousel-channel-height-desktop: 500px;
  --carousel-channel-height-big-desktop: 700px;
  // movie carousel heights / galleries / videos
  --carousel-movies-height-mobile: 225px;
  --carousel-movies-height-tablet: 250px;
  --carousel-movies-height-desktop: 310px;
  --carousel-movies-height-big-desktop: 400px;
  // poster size
  --carousel-movies-portrait-height-mobile: 530px;
  --carousel-movies-portrait-height-tablet: 444px;
  --carousel-movies-portrait-height-desktop: 491px;
  --carousel-movies-portrait-height-big-desktop: 520px;
  --aspect-ratio-portrait: 150 / 225;

  // general variables
  --brandColor: black;
  --brandColor-hover: rgb(26, 26, 26);
  --brandColor-contrast: white;

  --backgroundColor: white;
  --backgroundColor-hover: #f2f2f2;
  --backgroundColor-contrast: black;

  --textColor: black;
  --textColor-hover: #424040;
  --textColor-contrast: white;

  --borderRadius: 0;

  --ush-pagination-bullet-color: white;
  --swiper-pagination-bullet-inactive-color: white;

  --headerHeight: 100px;
  --headerHeightMobile: 80px;
  --headerBackgroundColor: var(--backgroundColor);
  --headerLinksColor: var(--textColor-contrast);
  --headerLinksTextTransform: none;
  --headerLinksLetterSpacing: 3px;
  --headerLinksFontWeight: 400;
  --inputBoxPlaceholderColor: rgb(128, 128, 128);

  --headerLinksActiveColor: var(--headerLinksColor);
  --headerLinksHoverColor: var(--headerLinksColor);

  --headerLinksHoverBorderBottom: 0px;
  --headerLinksActiveBorderBottom: 0px;
  --headerSubMenuItemsColor: var(--brandColor);
  --headerSubMenuBackgroundColor: var(--brandColor);
  --headerSearchIconColor: var(--headerLinksColor);

  --inputBoxBackground: #e5e5e5;

  @include for-tablet-landscape-up {
    --headerLinksTextTransform: uppercase;
    --headerBackgroundColor: var(--brandColor);
  }
}

.custom-page-container {
  &.channelNews__container {
    max-width: 100% !important;
  }
}

#showtime-streaming {
  padding: 3vw;
}

.showtime-list {
  > p-accordiontab {
    border-bottom: 1px solid --colorLightGrey;
  }
}
ush-location-selector .ush-near-me-button {
  --componentInCinemas-nearMetextBorder: 1px solid var(--backgroundColor);
  --componentInCinemas-nearMetextBorder--hover: none;
  --buttonBorderHover: 1px solid var(--backgroundColor);
}
#ush-showtimes-widget-wrapper {
  --buttonBackgroundHover: var(--backgroundColor);
  --buttonColorHover: var(--brandColor);
}
.ush-showtimes-nearestCities-wrapper {
  --componentInCinemas-nearestCity-headerTextColor: var(--backgroundColor);
  --componentInCinemas-nearestCity-buttonTextColor: var(--colorLightGrey);
}

body {
  font-family: 'mainfont';
  margin-top: var(--headerHeightMobile);
  @include for-tablet-landscape-up {
    margin-top: var(--headerHeight);
  }
}

ush-feature-channel-carousel {
  --swiper-navigation-color: var(--backgroundColor) !important;
  .channel-video-info {
    h2 {
      font-size: 35px !important;
      text-transform: uppercase;
    }
  }
  .channel-controls {
    flex-direction: column !important;
    align-items: center !important;
    .channel-video-buttons {
      flex-direction: row-reverse !important;
      flex-wrap: wrap !important;
      @media all and (max-width: $md) {
        align-items: center;
        flex-direction: column !important;
      }
      .cta-button,
      .play-trailer-button {
        color: var(--backgroundColor) !important;
      }
      .cta-button {
        border: 2px solid var(--backgroundColor) !important;
      }
    }
  }
}

ush-movies-carousel {
  column-gap: 5px !important;
  &.content-available {
    margin: 50px 0 !important;
  }
  .carousel__title {
    grid-column: 3 !important;
    margin-right: 8em;
    justify-self: center;
    --font-size-160: 1.8rem;
  }
  .carousel__view-all {
    grid-column: 3 !important;
    border: none !important;
    text-decoration: underline !important;
    text-transform: lowercase !important;
    background: none !important;
    color: var(--brandColor) !important;
    font-weight: normal !important;
    letter-spacing: 2px !important;
    margin-left: 10em;
    justify-self: center;
  }
  .carousel__left-arrow,
  .carousel__right-arrow {
    align-self: center;
    grid-row: 2 !important;
    z-index: 2;
    border: 1px solid var(--brandColor);

    &:hover {
      fa-icon {
        color: var(--brandColor) !important;
      }
      &:enabled {
        --buttonBackgroundHover: var(--backgroundColor);
        --buttonColorHover: var(--brandColor);
        --buttonBorderHover: 1px solid var(--brandColor);
      }
    }
  }
  .carousel__left-arrow {
    grid-column: 2 !important;
  }
  .carousel__right-arrow {
    grid-column: 4 !important;
    justify-self: end;
  }

  .carousel__wrapper {
    display: grid !important;
    .carousel__title {
      h2.carousel__title {
        text-transform: none;
      }

      shared-location-widget-carousels {
        display: none;
      }
    }
  }

  .carousel__content {
    ush-movie-card {
      .movie-title {
        text-align: center;
        letter-spacing: 1px;
        color: white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 20px;
      }
    }
  }
}
ush-header {
  .nav-logo img {
    height: 50px !important;
  }
  .header-wrapper {
    @include for-tablet-landscape-down {
      box-shadow: 1px 3px 6px #00000029;
    }
  }

  #header {
    .search-autocomplete-wrap {
      position: static;
      input.p-autocomplete-input {
        background-color: var(--inputBoxBackground);

        &::placeholder {
          color: var(--inputBoxPlaceholderColor);
        }
      }
    }
    .header-links {
      .nav-link {
        color: white !important;
        margin-right: 15px;
        padding-bottom: 20px;
        letter-spacing: 3px !important;
        font-weight: normal !important;
        display: flex;
        align-items: center;
        height: 30px;

        &.isChild {
          --headerLinksTextTransform: none;
        }
      }
    }

    .sub-menu {
      min-width: 160px !important;
      padding: 0 !important;
      box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.2);
      border-left: 1px solid var(--colorLightGrey);
      border-right: 1px solid var(--colorLightGrey);

      .nav-item {
        padding: 10px !important;

        &:first-child::before {
          display: none;
        }

        &::before {
          content: '';
          position: absolute;
          width: 90%;
          height: 1px;
          background-color: gray;
          top: 0;
          opacity: 0.4;
        }
      }

      .nav-link {
        transition: none !important;
        color: black !important;
      }

      .nav-item {
        &:hover,
        &:focus,
        &:active {
          background-color: #000000 !important;

          .nav-link {
            color: white !important;
          }
        }
      }
    }
  }
  #header-sm {
    &.page-content {
      --_navbar-toggler-color: var(--brandColor);

      .header-items {
        &.collapsed {
          --_navbar-toggler-color: var(--backgroundColor);
        }
        .navbar-toggler {
          &-icon {
            color: var(--_navbar-toggler-color);
          }
        }
      }

      div.header-collapse-items {
        &.collapsed {
          background-color: var(--brandColor);
        }

        .nav-child {
          scale: 1;
          margin-left: 0;
        }
      }
    }
  }
}

art-footer {
  #footer-container {
    padding-top: 0 !important;
  }
  #footer-subscribe-form {
    margin-top: 0 !important;
    padding-top: 0 !important;
    .subscribe-form {
      margin-top: 0 !important;
    }
  }

  .subscribe-form-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
    .subscribe-title,
    .subscribe-desc,
    .text-danger {
      color: black !important;
    }
    & > * {
      @include for-tablet-landscape-up {
        width: 50%;
      }
    }
  }

  .subscribe-form {
    background-color: transparent !important;

    .subscribe-title {
      font-size: 30px;
      font-family: bold;

      @include for-tablet-landscape-down {
        text-align: center;
      }
    }

    input {
      text-align: left;
      background-color: transparent;
      border-bottom: 1px solid black;
    }

    .button-secondary {
      border: 1px solid black;
      &:hover,
      &:active {
        span {
          color: white !important;
        }
      }
    }
  }

  .nav-logo img {
    height: 35px !important;
  }

  .group-1 {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;

    & > *:first-child {
      grid-column: 1;
      margin-right: 40px;
    }

    & > * {
      grid-column: 2;
    }

    &::after {
      content: '';
      position: absolute;
      right: -40px;
      height: 100%;
      width: 2px;
      background-color: gray;
      opacity: 0.5;
    }

    @include for-tablet-landscape-down {
      & > * {
        grid-column: 1;
      }

      &::after {
        display: none;
      }
    }
  }

  .address p {
    text-align: left;

    &:first-child {
      margin-top: 0;
    }
  }

  .group-2 {
    position: relative;
    top: 35px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;

    .footer-menu {
      margin-bottom: 15px !important;

      a {
        text-decoration: underline !important;
      }
    }
  }
}
.movieSlider {
  .page-content {
    max-width: unset !important;
  }
}

// Force image sizes
ush-carousel .carousel-movies {
  .slide-content {
    height: calc(var(--slide-width) * 9 / 16) !important;
    picture {
      height: 100% !important;
    }
  }
}

ush-carousel .carousel-channel {
  .ctrl-play {
    span {
      text-transform: none !important;
      font-weight: normal !important;
    }
  }
  @include for-tablet-portrait-down {
    .arrow-inside {
      --videoHeight: calc(var(--slide-width) * 9 / 16);
      top: calc(var(--videoHeight) + 10px);
      width: 40px !important;
      height: 40px !important;
      background: transparent !important;

      img {
        height: auto;
        width: 25px;
      }
    }

    .video-thumbnail-title {
      font-size: 25px !important;
      letter-spacing: 2px !important;
      text-transform: uppercase !important;
    }

    .controls {
      position: relative !important;
      padding: 0 !important;

      &::before {
        content: '';
        position: absolute;
        top: -30px;
        height: 30px;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }

    .ctrl-play {
      fa-icon {
        margin-right: 20px !important;
      }

      span {
        text-transform: none !important;
        font-weight: bold !important;
      }
    }
  }

  @include for-tablet-portrait-up {
    .inner-wrapper {
      justify-content: center;
      align-items: center;
      flex-flow: column;
      .control-buttons {
        margin-top: 30px;
      }

      .ctrl-detail {
        margin-left: 40px;
        padding: 4px 10px;
      }
    }

    .controls {
      position: absolute !important;
    }
    .arrow-inside {
      width: 80px !important;
      height: 80px !important;
      background: transparent !important;
      bottom: 10%;

      &.arrow-inside-left {
        left: 5%;
      }

      &.arrow-inside-right {
        right: 5%;
      }
    }
  }

  .dots-wrapper {
    display: none;
  }
}

ush-carousel .carousel-movies {
  .header-carousel {
    justify-content: center;
    align-items: baseline;
    margin-top: 30px;
  }
  .header-title {
    width: auto !important;
    margin: 0;
    text-transform: capitalize;
  }
  .title-inside {
    font-weight: bold !important;
    text-transform: uppercase !important;
    letter-spacing: 2px;
    left: 50% !important;
    transform: translateX(-50%);
    width: 100%;
    max-width: calc(var(--slide-width) - 10px) !important;
    text-align: center;
  }

  .viewAll {
    border: none !important;
    font-weight: normal !important;
    background: transparent;
    color: black;
    text-transform: lowercase;
    letter-spacing: 2px;
    text-decoration: underline;
    margin-left: 15px;

    &:hover,
    &:active,
    &:focus {
      border: none;
    }
  }

  .arrow-inside {
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
    border: 1px solid black;
    background-color: white;

    &.arrow-inside-left {
      left: 5%;
    }

    &.arrow-inside-right {
      right: 5%;
    }
  }

  .slide-content {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 70px;
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

.carousel-videos {
  .ctrl-play:not(.center) {
    display: none !important;
  }
}

ush-video-thumbnail {
  .video-thumbnail {
    background-color: black;
  }
  .control-header {
    .video-thumbnail-title {
      text-transform: uppercase !important;
    }

    .status span {
      font-weight: bold !important;
    }
  }

  .control-buttons {
    .ctrl-detail {
      background: transparent !important;
      color: white;
      border: 1px solid white !important;
      text-transform: capitalize;
      font-weight: normal;
      padding: 10px;

      &:hover {
        border-color: transparent !important;
      }
    }
  }

  .subtitle {
    text-align: center;
    font-size: 30px !important;

    @include for-tablet-landscape-down {
      font-size: 20px !important;
    }

    span {
      font-weight: bold !important;
    }
  }
}

ush-news-feed {
  .news-feed {
    margin-top: 0 !important;
    background-color: black;
    width: 100% !important;
    max-width: 100% !important;
    padding: 50px 5% !important;

    ush-news {
      max-width: 48%;

      .news-card {
        min-width: 200px !important;

        .news-card-img {
          object-position: top !important;
        }
      }

      @media all and (max-width: 500px) {
        .news-card-img {
          height: 200px;
        }
      }

      @media all and (min-width: 992px) {
        max-width: 32%;
        .news-card-img {
          height: 200px;
        }
      }

      @media all and (min-width: 1350px) {
        max-width: 23%;
      }

      @include for-tablet-landscape-down {
        max-width: 100%;
        width: 100%;
      }

      .read-more-content {
        display: none;
      }
    }

    .load-more {
      // display: none;
    }

    .header-title {
      display: flex;
      justify-content: center;
      align-items: baseline;
      color: white !important;
      text-align: center !important;
      text-transform: none !important;

      .news-view-all {
        display: block;
        margin-left: 15px;
        font-weight: normal;
        color: white;
        text-transform: none;
        letter-spacing: 2px;
        text-decoration: underline;
      }
    }

    @media all and (min-width: 1700px) {
      padding: 0 calc((100vw - 1700px) / 2);
    }
  }

  ush-news {
    .news-info * {
      color: white !important;
    }

    .title {
      font-size: 20px !important;
      letter-spacing: 3px;
    }

    .read-more a {
      position: relative;
      text-transform: lowercase !important;

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: gray;
      }
    }
  }

  .news-feed-load-more {
    margin: 0 auto;
    color: white !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.custom-page-subcontainer {
  padding-bottom: 0px !important;
}

.custom-about {
  .about-container {
    .team-member {
      img {
        aspect-ratio: 960/1219;
        object-fit: cover;
      }
    }
    video {
      max-height: 70vh;
      background: black;
    }
  }
}

.custom-home {
  .slider-container {
    width: 100% !important;
  }

  .header-title {
    width: 90%;
    max-width: 100% !important;
    margin: 0 auto;
    text-align: left !important;
  }
}

ush-movie-detail-page {
  .subtitle,
  .text {
    font-size: 18px !important;
  }

  .load-more-movie-detail {
    display: none !important;
  }

  @include for-tablet-landscape-down {
    .info-left {
      margin-right: 150px;
    }
  }

  .thumbnail-wrapper {
    @include for-tablet-landscape-up {
      height: 100% !important;
      max-height: unset !important;
    }
  }

  ush-video-thumbnail {
    width: 100%;
    picture {
      justify-content: center !important;
      background: black !important;
      img {
        width: auto !important;
      }
    }
    .controls {
      span {
        text-transform: uppercase !important;
        font-weight: normal !important;
        letter-spacing: 2px;
      }
      @include for-tablet-landscape-down {
        background-color: transparent !important;

        .video-thumbnail-title {
          text-shadow: none !important;
        }

        fa-icon {
          margin-right: 15px !important;
        }

        .video-thumbnail-title,
        fa-icon,
        span {
          color: white !important;
        }
      }
    }

    .controls {
      .video-thumbnail-title {
        color: black !important;
      }
      .inner-wrapper {
        align-items: center !important;
        .video-thumbnail-title {
          width: 100% !important;
        }
      }
      @include for-tablet-landscape-up {
        position: static !important;
        height: auto !important;
        padding: 20px 0 !important;
        transform: none !important;

        .inner-wrapper {
          display: flex;
          flex-flow: column;

          .video-thumbnail-title {
            text-shadow: none !important;
          }

          .control-buttons {
            margin-top: 20px !important;
          }

          .control-buttons fa-icon {
            color: white;
          }
        }
      }
    }

    .play-pause-buttons .play {
      display: flex;
    }

    .play-pause-buttons .play span {
      display: block !important;
    }

    .ctrl-play.center {
      display: flex !important;
    }

    .play-pause-buttons:not(.playing) {
      display: none;
    }

    .control-buttons {
      position: absolute;
      bottom: 175px;

      fa-icon {
        color: white !important;
      }
    }

    .play-pause-buttons:not(.playing) {
      .mute {
        display: none;
      }

      .play span {
        text-transform: none;
        color: black;
        margin-left: 6px;
      }
    }
  }

  .info-left > * {
    display: block;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  .movie-detail-content {
    position: relative !important;
    &::after {
      content: '';
      position: absolute;
      top: -20px;
      left: 50%;
      width: 100%;
      height: 1px;
      transform: translateX(-50%);
      background-color: gray;
    }
  }

  .title-outside,
  .title-inside {
    display: none !important;
  }

  .title {
    display: none !important  ;
  }

  .subtitle {
    font-weight: normal !important;
    font-size: 20px;
    letter-spacing: 1.6px;
  }

  ush-movie-tell-friends {
    .share-text {
      text-transform: lowercase;
      font-size: 18px !important;
      letter-spacing: 0.9px;
      line-height: 26px;
    }
  }

  ush-carousel {
    carousel-videos,
    .carousel-gallery {
      .slide-wrap {
        height: calc(var(--slide-width) * 9 / 16) !important;
        picture {
          height: 100% !important;
        }
      }
    }

    .carousel-videos {
      .slide-wrap {
        picture {
          height: 100% !important;
        }
      }
      .video-thumbnail.gradient::after {
        display: none !important;
      }

      .ctrl-play.center {
        display: flex !important;
      }

      .arrow-inside {
        height: 100px !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        background-color: transparent !important;
      }
    }

    .carousel-gallery {
      .arrow-inside {
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid black;
        background-color: white;

        &.arrow-inside-left {
          left: 5%;
        }

        &.arrow-inside-right {
          right: 5%;
        }
      }
    }
  }

  #movie-detail {
    &.hasVideos {
      .slider-external-wrapper.videos {
        position: relative;
        padding-top: 80px;
        &::before {
          content: 'Gallery';
          position: absolute;
          top: 0;
          margin-left: 5%;
          font-size: 45px;
          font-weight: bold;
        }
      }
    }

    &.hasGallery:not(.hasVideos) {
      .slider-external-wrapper.gallery {
        position: relative;
        padding-top: 80px;
        &::before {
          content: 'Gallery';
          position: absolute;
          top: 0;
          margin-left: 5%;
          font-size: 45px;
          font-weight: bold;
        }
      }
    }
  }
}

ush-movie-credits {
  @include for-tablet-landscape-up {
    .info-list li {
      display: flex;
      flex-flow: row;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    .subtitle {
      min-width: 150px;
    }
  }
}

ush-movie-more-information {
  @include for-tablet-landscape-up {
    position: relative;
    .more-info {
      display: flex;
      //flex-flow: row-reverse nowrap;
      flex-flow: column nowrap;
      justify-content: flex-end;
    }

    .languages-list,
    .genres-list {
      .crew:not(:first-child) {
        text-transform: lowercase !important;
      }
    }

    .info-list li {
      display: flex;
      flex-flow: row;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    .subtitle {
      min-width: 150px;
    }
  }

  .movie-pic {
    margin-top: 10px;
    @include for-tablet-landscape-down {
      margin-top: 10px;
    }

    @include for-tablet-landscape-up {
      margin-right: 10px;
    }
  }

  .third-party-info {
    display: flex !important;
    align-items: center !important;
    li {
      margin-top: 0 !important;
      margin-bottom: 0 !important ;

      a {
        filter: grayscale(1);
      }
    }
  }
}

ush-movie-tell-friends,
ush-social-links {
  .social-links a {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 31px !important;
    height: 31px !important;
    background-color: var(--brandColor) !important;
    border-radius: 0 !important;
    margin: 0 8px;

    fa-icon {
      color: white !important;
      font-size: 12px;
      border: none;
    }
  }
}

ush-showtime-streaming {
  .showtime-streaming {
    background-color: black !important;
    margin-bottom: 10px !important;
  }

  .p-selectbutton,
  .p-button {
    --selectBtnColor: white;
    text-transform: unset !important;
    text-decoration: none;
    font-size: 20px;
    letter-spacing: 1.6px;

    &:hover,
    &:focus,
    &:active {
      --selectBtnColorActive: white;
    }
  }

  .p-selectbutton .p-button {
    &:hover,
    &:focus,
    &:active {
      --selectBtnColorActive: white;
      border-bottom: 1px solid white !important;
      background: transparent !important;
    }
  }

  .p-highlight {
    background-color: black !important;
    color: white !important;
    border-bottom: 1px solid white !important;
  }

  ush-showtime-streaming-in-cinemas {
    ush-location-selector {
      input {
        background-color: transparent !important;
        border-bottom: 1px solid white !important;
        color: white !important;
        letter-spacing: 0.9px;

        &::placeholder {
          color: white !important;
        }
      }

      fa-icon,
      .pi {
        color: white !important;
      }

      .button {
        border: 1px solid white !important;

        span {
          text-transform: lowercase;
          font-size: 18px !important;
          letter-spacing: 0.9px;
        }

        &:hover,
        &:focus,
        &:active {
          background-color: white;
          span {
            color: black !important;
          }
        }
      }

      .showtime-cinema {
        border-bottom: 2px solid gray;
        span {
          font-weight: normal !important;
          font-size: 18px !important;
          text-transform: capitalize !important;
          letter-spacing: 0.9px;
          color: white !important;
        }
      }
      .showtime-cinema.active {
        background-color: white !important;
        border: none;
        span {
          color: black !important;
        }
      }
    }

    ush-performance-legacy {
      span {
        color: black !important;
      }

      .cinema-location {
        margin-left: 55px;
      }

      .cinema-location,
      .cinema-location a {
        color: gray !important;
      }

      .date-tag {
        background-color: transparent !important;

        * {
          color: black;
        }
      }

      .date-tag.active {
        background-color: black !important;

        * {
          color: white !important;
        }
      }

      .goToTicket {
        text-transform: initial !important;
        font-size: 20px;
        letter-spacing: 1.6px;

        & * {
          color: white !important;
        }
      }
    }

    .showtime-list {
      .p-accordion-tab {
        border-bottom: 1px solid var(--colorLightGrey);
      }
    }
  }

  ush-load-more-cinemas {
    .load-more-cinemas__button {
      background-color: var(--backgroundColor);
    }
  }

  ush-showtime-streaming-watch-at-home {
    #countrySelectWrapper {
      label {
        text-transform: lowercase;
        font-size: 18px !important;
        letter-spacing: 0.9px;
        line-height: 26px;
      }

      .p-dropdown {
        background-color: transparent !important;
      }

      .p-inputtext {
        color: white;
        border-bottom: 1px solid white;
      }
    }

    .streaming-list {
      justify-content: left !important;
    }
    .streaming-logo {
      filter: grayscale(1);
      transition: filter 0.4s;

      &:hover,
      &:active,
      &:focus,
      &:visited {
        filter: grayscale(0);
      }
    }

    .streaming-list li {
      border: 1px solid white;
      margin-right: 15px !important;
      margin-bottom: 15px !important;
    }
  }

  ush-showtime-streaming-subscribe {
    .subscribe-form {
      background-color: transparent !important;
      border: 1px solid black;

      .subscribe-title,
      .result-msg,
      .subscribe-desc {
        color: black !important;
      }

      .subscribe-title {
        font-weight: bold;
        font-size: 25px;
        line-height: 25px;
      }

      input {
        background-color: transparent;
        border-bottom: 1px solid black;
      }

      .button-secondary {
        color: black !important;
        border: 2px solid grey;
        font-weight: normal;
        font-size: 20px;
        letter-spacing: 1.6px;
        line-height: 24px;
        text-transform: capitalize;

        @include for-tablet-landscape-down {
          width: 100%;
          padding: 20px 0;
        }
        &:hover,
        &:active,
        &:focus,
        &:visited {
          color: white !important;
          * {
            color: white !important;
          }
        }
      }
    }
  }

  ush-showtime-streaming-at-festivals {
    .past-festivals-trigger {
      color: white !important;
    }
  }

  //  .tab-msg,
  .ush-error-msg {
    color: white !important;
  }
}

ush-movies-page {
  .movies-page-type {
    position: relative;
    display: flex !important;
    text-align: right;
    text-transform: none;
    align-items: center;
    white-space: nowrap;

    &::before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: gray;
      margin-right: 10px;
      margin-top: 8px;
    }
  }

  .p-inputtext {
    background-color: transparent !important;
  }

  #movies-form {
    .filters-msg {
      display: none !important;
    }

    .input-movie-cast-wrapper em {
      display: flex !important;
      right: auto !important;
      left: 5px;
    }

    #input-movie-cast {
      padding-left: 35px;
      margin-bottom: 20px;
    }

    input,
    .p-dropdown,
    .p-multiselect {
      background: transparent !important;
      border-bottom: 2px solid black !important;
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      --backgroundColor: black !important;
      color: black !important;
      background: transparent !important;
    }

    hr {
      display: none;
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      border-bottom: none;
      span {
        color: black;
      }
    }

    .p-multiselect,
    .p-dropdown {
      &-panel {
        --box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
      }
      .p-placeholder {
        --color-text: #6c757d;
      }

      .p-dropdown-item {
        &:hover {
          --color-text: #495057;
          --color-light-primary: #e9ecef;
        }
      }
    }
    input {
      &::placeholder {
        color: #6c757d;
      }
    }
    .p-multiselect {
      .p-checkbox-box {
        --color-gray: #ced4da;
        //--color-light-background
        --color-text: #495057;
        --border-radius: 3px;
      }
      .p-multiselect-item {
        &:hover {
          --color-text: #495057;
          --color-light-primary: #e9ecef;
        }
      }
    }
  }

  .filters-wrap {
    justify-content: space-around;
  }

  .filters-wrap > * {
    @include for-tablet-landscape-down {
      width: 100% !important;
      margin: 0 0 20px 0 !important;
    }

    @include for-tablet-landscape-up {
      margin: 0 10px 15px 10px !important;
    }
  }

  .movies-grid {
    justify-content: space-between;

    li {
      width: 32% !important;
      padding-top: calc(9 / 16 * 32%) !important;
      margin: 10px 0 !important;

      @include for-tablet-portrait-down {
        width: 90% !important;
        margin: 0px auto !important;
        margin-bottom: 30px !important;
        padding-top: calc(9 / 16 * 90%) !important;
      }
    }
  }

  .movies-wrapper:not(.movies-all) {
    .movies-grid {
      li:first-child {
        width: 90% !important;
        padding-top: calc(9 / 16 * 90%) !important;
        margin: 0px auto !important;
        margin-bottom: 30px !important;
      }
    }
  }

  .load-more {
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid black;
    padding: 10px 0 !important;
    span,
    em {
      font-weight: normal;
      text-transform: lowercase;
      letter-spacing: 1.6px;
      color: black;
    }

    &:hover,
    &:active,
    &:focus {
      padding: 10px 20px !important;
      border: none;
      border-bottom: 1px solid black !important;
    }
  }

  .p-dropdown-panel {
    background: var(--backgroundColor);
  }

  --dropdownTriggerColor: var(--brandColor);

  .ush-near-me-button {
    border: 0 !important;
  }
}

ush-movie-card {
  .card-front {
    .movie-title {
    }
  }

  .card-back {
    .movie-title {
    }

    .watchTrailer span {
      text-transform: none !important;
      letter-spacing: 1.6px !important;
    }

    .back-content {
      span,
      p {
        letter-spacing: 1.6px;
      }
    }

    .back-footer {
      .button {
        background: transparent !important;
        border: none;
        border-bottom: 1px solid white;

        span {
          color: white;
          text-transform: lowercase;
        }

        &:hover,
        &:active,
        &:focus {
          span {
            color: white;
          }
        }
      }
    }
  }
}

.custom-news {
  ush-news-feed {
    .news-feed {
      margin-top: 0 !important;

      padding-top: 60px;
    }

    .header-title {
      position: relative;
      display: block !important;
      text-align: right !important;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: 1px;
        width: calc(100% - 220px);
        background-color: gray;
        transform: translateY(-50%);
      }

      .news-view-all {
        display: none;
      }
    }

    .news-feed-content {
      ush-news {
        position: relative;
        margin-bottom: 60px;

        &::after {
          content: '';
          position: absolute;
          bottom: -50px;
          width: 100%;
          height: 1px;
          background-color: gray;
          opacity: 0.8;
        }

        &:last-child:after {
          display: none;
        }
      }

      ush-news {
        .news-card {
          .title {
            font-size: 35px !important;
          }

          .news-info {
            width: 100%;

            .date,
            .read-more-content {
              display: block;
            }
          }
        }
        .load-more {
          display: block;
        }
      }

      @include for-tablet-landscape-up {
        flex-direction: column;

        ush-news {
          max-width: 100%;

          .news-card {
            min-width: 200px;
            max-width: 100%;
            display: flex;
            flex-flow: row-reverse nowrap;
          }

          .news-card-img,
          .news-card-img img {
            width: 100%;
            height: auto;
            max-width: 400px;
            margin-left: 40px;
          }

          .news-card-img {
            height: 200px;
          }
        }
      }
    }
  }
}

ush-news-page {
  .news-page {
    flex-direction: column-reverse !important;
    margin-top: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .news-page-image-container {
    display: flex;
    justify-content: center;
    max-width: 100% !important;
    img {
      max-width: 1700px !important;
      width: 100vw !important;
      width: 100% !important;
      max-height: calc(100vh - 200px);
      object-fit: cover;
      object-position: top;
    }
  }

  .content-container {
    width: 90%;
    max-width: 860px !important;
    margin: 0 auto;
    padding-right: 0 !important;

    img {
      max-width: 100%;
    }
  }

  .read-more-content {
    line-height: 35px;
    font-size: 18px;
    letter-spacing: 1px;
  }

  .read-more-content.text {
    font-family: 'news' !important;
  }
}

ush-location-selector {
  .location-input {
    position: relative;

    input {
      padding-left: 35px !important;
    }
  }

  .ush-location-selector-icon {
    position: absolute !important;
    left: 10px !important;
    right: initial !important;

    & > * {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .search-icon {
    display: none !important;
  }
  .marker-icon {
    display: block !important;
  }
}

.subtitle {
  text-transform: unset !important;
}

ush-news .subtitle {
  text-transform: none !important;
}

// FIXES

.at-festivals-title {
  color: white !important;
}

.title,
.header-title,
.news-title {
  text-transform: none !important;
}

ush-search-movie {
  fa-icon {
    color: white !important;
  }

  .p-inputtext {
    background: white;
    border-bottom: 1px solid;
    font-size: 12px;
    letter-spacing: 1.85px;
  }
}

.usheru-logo {
  .usheru-name {
    text-decoration: underline !important;
  }

  @media all and (max-width: 500px) {
    margin-bottom: 20px;
  }
}

ush-feature-movie-hero {
  .hero-info {
    h2 {
      text-transform: uppercase;
    }
  }
}
