// Usheru theme for PrimeNG componentes
@use 'sass:math';
@import 'media-query.mixins';

$black: rgba(51, 51, 51);
$colorLightGrey: #e7e7e7;
$colorDarkGrey: #6c757d;

@mixin shadow() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
}

body {
  // Remove borders
  .p-widget {
    border: 0;
  }

  button {
    background: none;
    border: none;
  }

  // Panels

  .p-panel {
    .p-panel-content {
      border: none;
      padding-left: 0;
      padding-right: 0;
      @media all and (min-width: $md) {
        padding: 1rem;
      }
    }

    .p-panel-titlebar {
      display: inline-flex;
      width: 100%;
      justify-content: flex-end;
      border: 0;
      background-color: transparent;
      color: var(--backgroundColor);

      &:hover {
        background-color: var(--colorLightGrey);
      }

      /* change panel toggler icons */
      & .pi-plus::before {
        content: '\e903';
      }

      & .pi-minus::before {
        content: '\e902';
      }

      .p-panel-titlebar-toggler {
        margin: 0;
        background-color: transparent;
        color: var(--backgroundColor);

        &:focus {
          @include shadow();
        }
      }

      .header-msg {
        margin-right: 15px;
        text-transform: uppercase;

        @media all and (max-width: $sm) {
          font-size: 25px !important;
        }
      }
    }
  }

  // Form Field
  .p-fluid .p-button {
    width: 100%;
  }
  .p-field {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    flex-wrap: nowrap;
    flex-grow: 1;

    label {
      color: var(--brandColor);
      font-size: var(--filtersLabelFontSizeMobile);
      text-transform: var(--filtersLabelTextTransform);
      @include for-tablet-landscape-up {
        font-size: var(--filtersLabelFontSize);
      }
    }

    &.attached {
      label {
        display: flex;
        align-items: center;
        justify-content: var(--filtersLabelJustifyContent);
        color: var(--filtersLabelColor);
        height: var(--filtersHeight);
        width: var(--filtersLabelAttachedWidth);
        max-width: var(--filtersLabelAttachedWidth);
        background-color: var(--filtersLabelBackground);
        border-radius: var(--filtersLabelBorderRadius);
        line-height: var(--filtersLabelAttachedLineHeight);
        padding: var(--filtersLabelPadding);
        font-weight: var(--filtersLabelFontWeight);
        @include for-mobile-only {
          display: none;
        }
      }
      .field-box {
        .p-inputtext {
          border-radius: var(--filtersInputBorderRadius);
          @include for-mobile-only {
            border-radius: var(--borderRadius);
          }
        }
      }
    }
    .field-box {
      padding: 0;

      button {
        margin-left: 5px;
      }

      &.attached {
        display: flex;
      }
      &.attached .attached-label {
        color: var(--backgroundColor);
        display: flex;
        align-items: center;
        width: var(--filtersLabelAttachedWidth);
        max-width: var(--filtersLabelAttachedWidth);
        background-color: var(--brandColor);
        line-height: var(--filtersLabelAttachedLineHeight);

        border: 1px solid var(--brandColor);
        border-radius: 7px 0 0 7px;
        padding-left: 15px;
        padding: 0.5rem 0.5rem;

        *:first-child {
          margin-left: 0;
          width: 100%;
        }

        *:first-child {
          margin-left: 0;
        }

        *:last-child {
          margin-right: 0;
        }

        & > * {
          width: 100%;
        }

        & > .fit-width {
          padding: 0 25px;
        }
      }

      // & > * {
      //   margin: 0 5px;
      // }
    }
  }

  // Carousel

  .p-carousel-indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  // Toggle Button

  .p-togglebutton.p-button:focus {
    box-shadow: none;
  }

  .p-togglebutton.p-button {
    background: var(--backgroundColor);
    border-color: var(--brandColor);
    color: var(--brandColor);
  }

  .p-togglebutton.p-button.p-highlight {
    background: var(--brandColor);
    border-color: var(--brandColor);
    color: var(--backgroundColor);
  }

  .p-togglebutton.p-button.p-highlight:hover {
    background: var(--backgroundColor);
    border-color: var(--brandColor);
    color: var(--brandColor);
  }

  .p-inputtext,
  .p-component {
    font-family: 'mainfont', Helvetica, sans-serif;
  }

  // Select Button

  .p-selectbutton {
    display: flex;

    // THIS RULE CAN CAUSE SOME PROBLEMS
    // * {
    //   border-radius: 0 !important;
    // }

    .p-button {
      padding: 5px;
      text-transform: uppercase;

      &:not(.p-state-disabled):not(.p-state-active).p-state-focus {
        @include shadow();
      }
    }

    .p-state-active {
      background-color: var(--backgroundColor) !important;
      color: var(--brandColor) !important;
    }
  }

  p-selectButton.tabs {
    .p-selectbutton {
      margin-bottom: 15px;
    }

    .p-button {
      padding-top: 10px;
      margin: 0 5px;
      background-color: transparent;
      color: #cbcbcb;
      text-align: left;
      border: none;
      border-bottom: 3px solid #cbcbcb;

      &:hover {
        border-bottom: 3px solid gray;
        background: transparent;
      }
    }

    .p-state-active {
      border-bottom: 3px solid var(--brandColor) !important;
      background-color: transparent !important;
      color: var(--brandColor) !important;
    }
  }

  .p-buttonset .p-button {
  }

  // Highlight button
  .p-selectbutton {
    .p-button {
      &:first-child {
        border-radius: var(--borderRadius) 0 0 var(--borderRadius);
      }

      &:last-child {
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
      }

      &.p-highlight,
      &.p-highlight:hover {
        background: var(--backgroundColor);
        color: var(--brandColor);
        border: 0;
      }

      &.p-highlight:focus {
        box-shadow: 0 0 0 0.2rem rgba(var(--brandColorRGB), 0.5);
      }
    }

    &.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
      border-color: transparent;
    }
  }

  p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: initial;

    &:not(.p-highlight) {
      border-color: #ced4da;
    }
  }

  // Autocomplete

  .p-autocomplete {
    width: 100%;

    .p-state-highlight {
      background-color: var(--brandColor) !important;
      color: var(--backgroundColor) !important;
    }

    &.p-autocomplete-multiple .p-state-focus {
      @include shadow();
    }
  }

  // Dropdown

  // fix width
  .p-fluid down {
    min-width: 0;
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
    height: var(--filtersHeight);
    border-radius: 0;
    border-top-right-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    @include for-mobile-only {
      border-radius: var(--borderRadius);
    }
    &:not(.p-disabled).p-focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--brandColorRGB), 0.5);
    }

    &:not(.p-disabled).p-focus,
    &:not(.p-disabled):hover,
    &:not(.p-disabled):visited,
    &:not(.p-disabled):focus {
      border-color: var(--brandColor);
    }

    .p-dropdown-label-container {
      width: 100%;
    }

    .p-dropdown-label {
      display: flex;
      align-items: center;
      background: transparent;
    }

    .p-dropdown-trigger {
      background-color: var(--dropdownTriggerBackgroundColor);
      color: var(--dropdownTriggerColor);
      border-radius: var(--filtersInputBorderRadius);
    }

    .p-dropdown-filter-icon {
      color: $black !important;
    }

    .p-dropdown-item.p-state-highlight {
      color: var(--backgroundColor) !important;
      background-color: var(--brandColor) !important;
    }

    .p-dropdown-clear-icon {
      right: 3em;
    }

    &:not(.p-state-disabled).p-state-focus {
      @include shadow();
    }
  }

  p-dropdown .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    color: var(--dropdownTriggerColor);
    text-align: center;
  }

  // Multiselect

  .p-multiselect {
    height: var(--filtersHeight);
    max-height: var(--filtersHeight);
    width: 100%;

    &:not(.p-disabled):hover {
      border-color: var(--brandColor);
    }

    &:not(.p-disabled).p-focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--brandColorRGB), 0.5);
    }

    .p-multiselect-trigger {
      background-color: var(--dropdownTriggerBackgroundColor);

      .p-multiselect-trigger-icon {
        left: 0;
        color: var(--dropdownTriggerColor);
      }
    }

    .p-multiselect-filter-container .p-multiselect-filter-icon {
      color: $black !important;
      right: 1.8em !important;
    }
    .p-multiselect-label {
      height: var(--filtersHeight);
      display: flex;
      align-items: center;
    }

    .p-multiselect-item {
      .p-chkbox {
        width: 20px !important;

        & .p-state-active {
          border: none;
          background-color: var(--backgroundColor) !important;
          color: var(--brandColor) !important;
        }
      }

      &.p-state-highlight {
        background-color: var(--brandColor) !important;
        color: var(--backgroundColor) !important;
      }

      &.p-state-default {
        border: 1px solid var(--backgroundColor);
      }

      &:focus {
        @include shadow();
      }
    }
  }

  .p-inputtext:not(.p-placeholder):not(.p-disabled) {
    height: var(--filtersHeight);
    color: var(--inputBoxColor);
    border: var(--formFieldBorder);
    // border-bottom: var(--filtersInputBorderBottom);

    &:enabled:hover,
    &:hover,
    &:focus,
    &:active {
      border-color: var(--brandColor);
    }
  }

  .p-dropdown,
  .p-multiselect {
    border-radius: var(--borderRadius);
    border: var(--filtersInputBorder);
    border-bottom: var(--filtersInputBorderBottom);
  }

  .p-dropdown-filter.p-inputtext {
    border: 1px solid transparent;
    border-radius: var(--borderRadius);
  }

  // Calendar

  .p-calendar {
    display: flex;

    .p-inputtext {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-button {
      height: var(--filtersHeight);
      margin: 0;
      background-color: var(--brandColor);
      color: var(--backgroundColor);
    }

    .p-state-active {
      background-color: var(--brandColor) !important;
      color: var(--backgroundColor) !important;
    }
  }

  // dialog

  p-dialog .p-dialog .p-dialog-header {
    border-radius: 0;
  }

  p-dialog .p-dialog .p-dialog-footer {
    border-radius: 0;
  }
}

// MultiSelectBox - Not a PrimeNG component
.multiselectbox {
  display: flex;
  flex-flow: row wrap;
  justify-content: var(--filtersMultiselectboxJustifyContent);
  gap: var(--filtersMultiselectboxGap);

  &,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    $calc1: calc(100% / var(--filtersMultiselectboxItemsPerRow));
    $calc2: calc(
      (var(--filtersMultiselectboxItemsPerRow) - 1) * var(--filtersMultiselectboxGap) /
        var(--filtersMultiselectboxItemsPerRow)
    );
    width: calc((#{$calc1}) - (#{$calc2}));
    // padding: 0 3px;
    // margin-bottom: 5px;
    text-align: center;

    @media all and (max-width: $md) {
      $calc1: calc(100% / var(--filtersMultiselectboxItemsPerRowMD));
      $calc2: calc(
        ((var(--filtersMultiselectboxItemsPerRowMD) - 1) * var(--filtersMultiselectboxGap)) /
          var(--filtersMultiselectboxItemsPerRowMD)
      );
      width: calc((#{$calc1}) - (#{$calc2}));
    }

    @media all and (max-width: $sm) {
      width: calc(100% / 2);
      margin: 0;

      $calc1: calc(100% / var(--filtersMultiselectboxItemsPerRowSM));
      $calc2: calc(
        ((var(--filtersMultiselectboxItemsPerRowSM) - 1) * var(--filtersMultiselectboxGap)) /
          var(--filtersMultiselectboxItemsPerRowSM)
      );
      width: calc((#{$calc1}) - (#{$calc2}));
    }

    p-button {
      width: 100%;

      .p-button {
        color: var(--filtersMultiselectboxButtonColor);
        border-radius: var(--borderRadius);
        background-color: var(--filtersMultiselectboxButtonBackground);
        border: var(--filtersMultiselectboxButtonBorder);
        border-color: var(--filtersMultiselectboxButtonBorderColor);
        padding: var(--filtersMultiselectboxPadding);
        font-weight: var(--filtersMultiselectboxFontWeight);
        text-transform: var(--filtersMultiselectboxTextTransform);
        box-shadow: none;
        &:hover,
        &:focus {
          color: var(--filtersMultiselectboxButtonHoverColor);
          background-color: var(--filtersMultiselectboxButtonHoverBackground);
          border-color: var(--filtersMultiselectboxButtonHoverBorderColor);
        }
      }

      &.active .p-button {
        color: var(--filtersMultiselectboxButtonActiveColor);
        background-color: var(--filtersMultiselectboxButtonActiveBackground);
        border-color: var(--filtersMultiselectboxButtonActiveBorderColor);
      }
    }
  }
}

// input texts

.p-inputtext,
.p-dropdown,
.p-multiselect,
.p-autocomplete-input {
  border-radius: var(--borderRadius);
  background: var(--inputBoxBackground);
  &:enabled:hover {
    //border-color: transparent;
  }
  &:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--brandColorRGB), 0.5);
    //border-color: transparent;
  }
}

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--brandColorRGB), 0.5);
      border-color: var(--brandColor);
    }
    &:hover {
      border-color: var(--brandColor);
    }
  }
}

// grid
.p-grid {
  margin-right: 0;
  margin-left: 0;
}

// p-carousel indicator

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: var(--brandColor);
}

.p-col {
  min-width: 0;
}

.p-button-label {
  flex-shrink: 0 !important;
}

li {
  .p-button-label {
    flex-shrink: 1 !important;
  }
}

// Checkbox

.p-field-checkbox.disabled {
  opacity: 0.4;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--brandColor);
  color: var(--backgroundColor);
  border-color: var(--brandColor);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--brandColor);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--brandColor);
  background: var(--brandColor);
  color: var(--backgroundColor);
}

.video-thumbnail {
  .ctrl-play {
    fa-icon {
      line-height: 0;
    }
  }
}

// p grid padding

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  padding: 0;
}

// // overide primeng styles
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item * {
//   color: #495057;
// }
